import { Environment } from './types';

export const environment: Environment = {
  production: true,
  featureSet: 'landshypotek',
  showErrorTraces: false,
  oauthBaseUrl: 'https://auth.fcgapps.com',
  oauthClientId: '6a07fur0uic4ce24orh9m10je5',
  oauthScopes:
    'profile+openid+email+https://oasys-landshypotek-api.fcgapps.com/init',
  apiBaseUrl: 'https://8842l3rcxe.execute-api.eu-north-1.amazonaws.com/Prod',
  documentationApiUrl:
    'https://atlk65auk3.execute-api.eu-north-1.amazonaws.com/documentation/oasys',
  notificationApiBaseUrl:
    'https://2s5mv1o9l5.execute-api.eu-north-1.amazonaws.com',
};
